#root {
  height: 100%;
}

.mos {
  height: 100%;
  background-color: #F5F6FA;
  padding-top: 64px;

  >div {
    &:nth-child(1) {
      background-color: #fff;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99;
      height: 64px;
      box-shadow: 0 0 8px rgba($color: #000000, $alpha: 0.2);

      >div {
        min-width: 1280px;
        max-width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;

        >div {
          height: 100%;
        }

        >.left {
          display: flex;
          align-items: center;

          >span {
            width: 64px;
            height: 64px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .logo {
            // display: block;
            // height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            // justify-content: center;
            // padding: 0 10px;
          }
        }

        >.right {
          display: flex;
          align-items: center;
          padding-right: 10px;

          .btns {
            display: flex;
            align-items: center;
            margin-right: 20px;

            >span {
              width: 30px;
              height: 30px;
              background-color: #f3f4f5;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              cursor: pointer;
              transition-duration: 300ms;

              &:hover {
                color: #1890ff;
              }
            }
          }

          .user {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              &+span {
                margin-left: 5px;
              }
            }
          }
        }

      }
    }

    &:nth-child(2) {
      height: 100%;
      display: flex;

      >div {
        &:nth-child(1) {
          width: calc((100% - 1920px)/2 + 228px);
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          min-width: 228px;
          transition-duration: 300ms;

          >.ant-menu {
            transition-duration: 300ms;
            width: 208px;
            overflow-y: auto;
            overflow-x: hidden;
            border-radius: 10px;
          }
        }

        &:nth-child(2) {
          overflow-y: auto;
          flex-grow: 1;
          padding: 10px;
          padding-left: 0;
          transition-duration: 300ms;

          >.ant-layout {
            transition-duration: 300ms;
            max-width: 1682px;
            min-width: 1042px;
          }
        }
      }

      &.collapsed {
        >div {
          &:nth-child(1) {
            width: calc((100% - 1920px)/2 + 84px);
            min-width: 84px;

            >.ant-menu {
              width: 64px;
            }
          }

          >.ant-layout {
            max-width: 1826px;
            min-width: 1186px;
          }
        }
      }
    }
  }
}