@import "~antd/dist/antd.css";

//浏览器滚动条
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background: 000;
}

::-webkit-scrollbar-track-piece {
  background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  background: #d0d0d0;
}

::-webkit-scrollbar-thumb:hover {
  background: #3b3b3b;
}

::-webkit-scrollbar-corner {
  background: #535353;
}

::-webkit-scrollbar-resizer {
  background: #ff6e00;
}

// 颜色
$orange: #fa8c16;
$gray: #bfbfbf;
$red: #f5222d;
$green: #52c41a;
$blue: #1890ff;
$purple: #722ed1;

.orange {
  color: $orange;
}

.gray {
  color: $gray;
}

.red {
  color: $red;
}

.green {
  color: $green;
}

.blue {
  color: $blue;
}

.purple {
  color: $purple;
}

// 按钮
.btn {
  color: #fff;

  &.orange {
    border-color: $orange;
    background-color: $orange;
  }

  &.red {
    border-color: $red;
    background-color: $red;
  }

  &.green {
    border-color: $green;
    background-color: $green;
  }

  &.purple {
    border-color: $purple;
    background-color: $purple;
  }

  &:hover {
    opacity: 0.8;
  }
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &.orange {
    background-color: $orange;
  }

  &.red {
    background-color: $red;
  }

  &.green {
    background-color: $green;
  }
}

._list {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  >.title {
    margin-bottom: 10px;
    flex-shrink: 0;

    .text {
      font-weight: 600;
      font-size: 16px;
    }
  }

  >.filter {
    margin-bottom: 10px;
    flex-shrink: 0;
  }

  >.ope {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;

  .list_top {
    background-color: #fff;
    // padding: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.filter {
      padding: 10px;
      display: flex;
      flex-direction: column;

      >div {
        +div {
          margin-top: 10px;
        }
      }

      .item {
        display: flex;
        align-items: center;
      }

      .ope {
        margin: 0;
      }
    }

    // > div {
    //   display: flex;
    //   flex-wrap: wrap;
    //   align-items: initial;
    // }
    .ope {
      margin: 10px;
      margin-bottom: 0px;
      flex-shrink: 0;
      flex-grow: 1;
      justify-content: flex-end;

      .anticon {
        height: 32px;
        width: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }
    }

    // .search {
    //   display: flex;
    //   flex-wrap: wrap;
    //   flex-grow: 1;

    //   .ant-form-item {
    //     margin: 10px 0 0 10px;
    //   }

    //   // >div{
    //   //   +div{
    //   //     margin-left: 10px;
    //   //   }
    //   // }
    //   .btn {
    //     margin: 10px 0 0 10px;
    //   }

    //   .ant-select {
    //     min-width: 196px;
    //   }
    // }
  }

  .table {
    overflow: auto;

    .ant-spin-nested-loading {
      height: 100%;
    }

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      >.ant-table {
        overflow: auto;

        // flex-grow: 1;
        .ant-table-container {
          display: flex;
          flex-direction: column;
          height: 100%;

          .ant-table-header {
            flex-shrink: 0;
          }

          .ant-table-body {
            flex-grow: 1;
            max-height: initial !important;
          }
        }
      }

      .ant-pagination {
        flex-shrink: 0;
        height: 30px;
        align-items: center;
        margin: 0;
        margin-top: 10px;
      }
    }

    .pic {
      max-height: 80px;
      width: initial;
      display: block;

      // img {
      //   width: initial;
      //   display: block;
      // }
    }

    .ico {
      height: 100%;
      display: flex;

      img {
        margin: auto;
        width: 18px;
        height: 18px;
        display: block;
      }
    }

    .avatar {
      height: 60px;
      width: 60px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flex {
      display: flex;
      align-items: center;
    }

    .inline-ico {
      display: flex;
      align-items: center;

      img {
        display: block;
        max-height: 24px;
        margin-right: 5px;
      }
    }
  }

  // .choice {
  //   position: absolute;
  //   display: none;
  //   bottom: 0;
  //   right: 10px;
  //   left: 10px;
  //   background-color: #fff;
  //   padding: 10px;
  //   box-shadow: 0 0 4px rgba(0, 21, 41, 0.08);
  // }

  .around {
    display: flex;
    flex-grow: 1;
    overflow: auto;

    >div {
      +div {
        margin-left: 10px;
      }
    }

    .main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.form {
  .note {
    color: rgba(0, 0, 0, 0.45);
  }

  .multiple {
    margin-bottom: 0;

    .ant-form-item-control-input-content {
      display: flex;

      .ant-form-item {
        flex: 1;

        +div {
          margin-left: 10px;
        }
      }
    }
  }

  .input-btn {
    width: 100%;

    .ant-space-item:nth-child(1) {
      flex-grow: 1;
    }
  }

  .ant-form-item {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .list {

    .ope,
    .search {
      margin-bottom: 10px;
    }
  }

  .ant-checkbox-wrapper {
    margin: 3px 10px 3px 0;
  }

  .tag-input {
    width: 78px;
    margin-right: 8px;
  }

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
  }

  .edit-tag {
    user-select: none;
  }

  // .ant-form-item-control-input-content {
  //   z-index: 999;
  // }
}

.form_space {
  width: 100%;

  .ant-space-item {
    &:nth-child(1) {
      flex-grow: 1;
    }
  }
}

.modal_tabs {
  .btn {
    margin-bottom: 10px;
  }
}

.config {
  background-color: #fff;
  padding: 20px;

  .ant-form {
    width: 80%;
  }
}

.my {
  padding: 20px;
  background-color: #fff;

  .avatar {
    img {
      border-radius: 50%;
      width: 128px;
      height: 128px;
      margin-right: 20px;
    }
  }

  .a {
    color: #1890ff;
    cursor: pointer;
  }

  .ant-row {
    margin: 20px 0;
  }
}

.save {
  padding: 20px;
  background-color: #fff;
  overflow: auto;

  .form {
    width: 1200px;

    .pic {
      max-height: 64px;
      width: initial;
      display: block;
    }
  }

}

.lar {
  display: flex;
  flex-grow: 1;
  overflow: auto;

  >div {
    &:first-child {
      flex-shrink: 0;
      margin-right: 10px;
    }

    &:last-child {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.print-template {
  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
}

.chart {
  background-color: #fff;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.webview {
  padding: 20px;
}

.overview {
  overflow: auto;

  .blocks {
    display: flex;

    .block+.block {
      margin-left: 10px;
    }
  }

  .block {
    margin-bottom: 20px;

    >.title {
      border-left: 3px solid $blue;
      padding-left: 5px;
      font-weight: 600;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 17px;
      display: flex;
      align-items: center;

      .select {
        margin-left: 20px;
        font-weight: normal;
        font-size: 12px;

        span {
          cursor: pointer;
        }

        .on {
          font-size: initial;
        }
      }
    }

    >.con {
      display: flex;

      .data {
        display: flex;
        background-image: linear-gradient(to right, #fc8568, #ff704d);
        color: #fff;
        padding: 15px;
        border-radius: 10px;
        align-items: center;
        width: 170px;
        box-shadow: 2px 2px 3px rgba($color: #000000, $alpha: 0.1);
        font-size: 15px;
        flex-shrink: 0;
        cursor: pointer;

        .number {
          font-size: 18px;
          font-weight: 500;
        }

        .left {
          width: 42px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba($color: #fff, $alpha: 0.4);
          font-size: 24px;
          border-radius: 10px;
          border: 1px solid rgba($color: #fff, $alpha: 0.7);
        }

        .right {
          margin-left: 10px;
        }

        +.data {
          margin-left: 10px;
        }

        &:nth-child(2) {
          background-image: linear-gradient(to right, #32c5e1, #20c0e0);
        }

        &:nth-child(3) {
          background-image: linear-gradient(to right, #febb33, #f7ae1c);
        }

        &:nth-child(4) {
          background-image: linear-gradient(to right, #5ec298, #45c28e);
        }

        &:nth-child(5) {
          background-image: linear-gradient(to right, #fc85a3, #f96086);
        }
      }

      .data_1 {
        background-color: #fff;
        padding: 15px;
        border-radius: 10px;
        flex-shrink: 0;

        .top {
          display: flex;
          align-items: center;

          .ico {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #f7ae1c, $alpha: 0.4);
            font-size: 24px;
            border-radius: 10px;
            border: 1px solid rgba($color: #f7ae1c, $alpha: 0.7);
            color: #f7ae1c;
          }

          .text {
            margin-left: 10px;
            font-size: 15px;
          }
        }

        .bottom {
          margin-top: 15px;
          display: flex;

          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 40px;

            +.item {
              margin-left: 15px;
            }

            .number {
              font-size: 18px;
              font-weight: 500;

              span {
                font-size: 12px;
              }
            }
          }
        }

        +.data_1 {
          margin-left: 10px;
        }

        &:nth-child(2) {
          .top {
            .ico {
              background-color: rgba($color: #45c28e, $alpha: 0.4);
              border: 1px solid rgba($color: #45c28e, $alpha: 0.7);
              color: #45c28e;
            }
          }
        }

        &:nth-child(3) {
          .top {
            .ico {
              background-color: rgba($color: #20c0e0, $alpha: 0.4);
              border: 1px solid rgba($color: #20c0e0, $alpha: 0.7);
              color: #20c0e0;
            }
          }
        }

        &:nth-child(4) {
          .top {
            .ico {
              background-color: rgba($color: #f96086, $alpha: 0.4);
              border: 1px solid rgba($color: #f96086, $alpha: 0.7);
              color: #f96086;
            }
          }
        }

        &:nth-child(5) {
          .top {
            .ico {
              background-color: rgba($color: #ff704d, $alpha: 0.4);
              border: 1px solid rgba($color: #ff704d, $alpha: 0.7);
              color: #ff704d;
            }
          }
        }
      }

      &.announcement {
        background-color: #fff;
        padding: 15px;
        border-radius: 10px;
        width: 360px;
        flex-direction: column;
        height: 420px;

        .item {
          .top {
            font-weight: 800;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .bottom {
            .date {
              color: #aaa;
            }
          }
        }
      }

      .echarts {
        width: 780px;
        height: 420px;
        background-color: #fff;
        padding: 15px;
        border-radius: 10px;
      }
    }
  }

  // .target {
  //   background-color: #fff;
  //   display: flex;
  //   border-radius: 5px;
  //   height: 60px;
  //   align-items: center;

  //   .item {
  //     display: flex;
  //     align-items: center;
  //     width: 200px;
  //     justify-content: center;

  //     .anticon {
  //       color: $blue;
  //       font-size: 18px;
  //     }

  //     .label {
  //       margin: 0 7px;
  //     }

  //     .number {
  //       font-size: 22px;
  //       font-weight: 500;
  //     }
  //   }
  // }
}

.echarts {
  background-color: #fff;
  flex-grow: 1;
  padding: 10px;
}

// 样式修正
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-color: rgba(0, 0, 0, 0.06);
}

.ant-upload-list-item-info::before {
  left: 0;
}

.page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .msg {
    .num {
      color: #1890ff;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .ant-pagination {
      margin-left: 10px;
    }
  }
}

// 动画部分
.show {
  display: initial !important;
  animation: show 300ms;
}

.hide {
  display: none !important;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}