.search {

    .ant-form-item {
        margin-bottom: 10px;

        .ant-picker,
        .ant-input-number {
            width: 100%;
        }
    }


    // >div{
    //   +div{
    //     margin-left: 10px;
    //   }
    // }
    // .btn {
    //     margin: 10px 0 0 10px;
    // }

    // .ant-select {
    //     min-width: 196px;
    // }
}