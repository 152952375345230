.table {
    flex-grow: 1;
    overflow: auto;

    .ant-spin-nested-loading {
        height: 100%;
    }

    .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.ant-table {
            overflow: auto;

            // flex-grow: 1;
            .ant-table-container {
                display: flex;
                flex-direction: column;
                height: 100%;

                .ant-table-header {
                    flex-shrink: 0;
                }

                .ant-table-body {
                    flex-grow: 1;
                    max-height: initial !important;
                }
            }
        }

        .ant-pagination {
            flex-shrink: 0;
            height: 30px;
            align-items: center;
            margin: 0;
            margin-top: 10px;
        }
    }

    .ope {
        span {
            cursor: pointer;
            color: #1890ff;
            transition-duration: 0.3s;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}