.detail {
    >div {
        display: flex;

        >div {
            &:first-child {
                width: 15%;
                text-align: right;
                flex-shrink: 0;
            }

            .ant-image {
                .ant-image-img {
                    max-height: 120px;

                }

                margin: 5px 0;
                margin-right: 10px;
            }
        }

        &+div {
            margin-top: 5px;
        }
    }
}